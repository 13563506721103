<template>
    <div class="app-container">
        <div class="filter-container">
            <el-input class="filter-item" v-model="listQuery.keyword" placeholder="请输入姓名/手机号码" style="width: 220px;" clearable/>
            <el-date-picker class="filter-item" v-model="listQuery.start_date" type="date" value-format="yyyy-MM-dd" placeholder="开始日期" clearable></el-date-picker>
            <el-date-picker class="filter-item" v-model="listQuery.end_date" type="date" value-format="yyyy-MM-dd" placeholder="截止日期" clearable></el-date-picker>
            <el-select class="filter-item" v-model="listQuery.settle_status" placeholder="结算状态" style="width: 140px" clearable>
                <el-option label="未结算" :value="0"></el-option>
                <el-option label="已结算" :value="1"></el-option>
            </el-select>
            <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
            <el-button class="filter-item" type="primary" icon="el-icon-download" @click="handleExportExcel">导出</el-button>
        </div>
        <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
            <el-table-column align="center" label="ID" width="80">
                <template slot-scope="scope">
                    <!-- {{ scope.$index }} -->
                    {{ scope.row.id }}
                </template>
            </el-table-column>
            <el-table-column label="姓名" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.sendman.name }}
                </template>
            </el-table-column>

            <el-table-column label="手机号" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.sendman.phone }}
                </template>
            </el-table-column>

            <el-table-column label="服务类型" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.attendance_code.service_type === 1" type="primary">投餐服务</el-tag>
                    <el-tag v-else-if="scope.row.attendance_code.service_type === 2" type="danger">上楼服务</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="上班时间" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.attendance_code.clock_in }}
                </template>
            </el-table-column>

            <el-table-column label="下班时间" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.attendance_code.clock_out }}
                </template>
            </el-table-column>

            <el-table-column label="上班打卡时间" min-width="150" align="center">
                <template slot-scope="scope">
                    {{ scope.row.clock_in }}
                </template>
            </el-table-column>

            <el-table-column label="下班打卡时间" min-width="150" align="center">
                <template slot-scope="scope">
                    {{ scope.row.clock_out }}
                </template>
            </el-table-column>

            <el-table-column label="迟到时间(分钟)" min-width="80" align="center">
                <template slot-scope="scope">
                    {{ scope.row.late_time }}
                </template>
            </el-table-column>

            <el-table-column label="早退时间(分钟)" min-width="80" align="center">
                <template slot-scope="scope">
                    {{ scope.row.early_leave_time }}
                </template>
            </el-table-column>

            <el-table-column label="是否旷工" min-width="80" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.is_absenteeism === 0" type="success">否</el-tag>
                    <el-tag v-else-if="scope.row.is_absenteeism === 1" type="danger">是</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="有效时长(分钟)" min-width="80" align="center">
                <template slot-scope="scope">
                    {{ scope.row.effective_time }}
                </template>
            </el-table-column>

            <el-table-column label="结算状态" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.settle_status === 0" type="info">未结算</el-tag>
                    <el-tag v-else-if="scope.row.settle_status === 1" type="success">已结算</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="考勤薪资" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.attendance_salary }}
                </template>
            </el-table-column>

            <el-table-column label="订单提成" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.order_reward }}
                </template>
            </el-table-column>

            <el-table-column label="当天工资" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ Number(scope.row.attendance_salary) + Number(scope.row.order_reward) }}
                </template>
            </el-table-column>
        </el-table>

        <el-table style="display: none" id="exportTable" v-loading="listLoading" :data="exportExcels" element-loading-text="Loading" fit highlight-current-row>
            <el-table-column label="姓名" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.sendman.name }}
                </template>
            </el-table-column>

            <el-table-column label="手机号" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.sendman.phone }}
                </template>
            </el-table-column>

            <el-table-column label="服务类型" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.attendance_code.service_type === 1" type="primary">投餐服务</el-tag>
                    <el-tag v-else-if="scope.row.attendance_code.service_type === 2" type="danger">上楼服务</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="上班时间" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.attendance_code.clock_in }}
                </template>
            </el-table-column>

            <el-table-column label="下班时间" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.attendance_code.clock_out }}
                </template>
            </el-table-column>

            <el-table-column label="上班打卡时间" min-width="150" align="center">
                <template slot-scope="scope">
                    {{ scope.row.clock_in }}
                </template>
            </el-table-column>

            <el-table-column label="下班打卡时间" min-width="150" align="center">
                <template slot-scope="scope">
                    {{ scope.row.clock_out }}
                </template>
            </el-table-column>

            <el-table-column label="迟到时间(分钟)" min-width="80" align="center">
                <template slot-scope="scope">
                    {{ scope.row.late_time }}
                </template>
            </el-table-column>

            <el-table-column label="早退时间(分钟)" min-width="80" align="center">
                <template slot-scope="scope">
                    {{ scope.row.early_leave_time }}
                </template>
            </el-table-column>

            <el-table-column label="是否旷工" min-width="80" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.is_absenteeism === 0" type="success">否</el-tag>
                    <el-tag v-else-if="scope.row.is_absenteeism === 1" type="danger">是</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="有效时长(分钟)" min-width="80" align="center">
                <template slot-scope="scope">
                    {{ scope.row.effective_time }}
                </template>
            </el-table-column>

            <el-table-column label="结算状态" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.settle_status === 0" type="info">未结算</el-tag>
                    <el-tag v-else-if="scope.row.settle_status === 1" type="success">已结算</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="考勤薪资" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.attendance_salary }}
                </template>
            </el-table-column>

            <el-table-column label="订单提成" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.order_reward }}
                </template>
            </el-table-column>

            <el-table-column label="当天工资" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ Number(scope.row.attendance_salary) + Number(scope.row.order_reward) }}
                </template>
            </el-table-column>
        </el-table>

        <div class="pagination-container">
            <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>
    </div>
</template>

<script>
    import request from "@/utils/request";
    import { mapGetters } from "vuex";
    import FileSaver from "file-saver";
    import XLSX from "xlsx";

    export default {
        data() {
            return {
                list: null,
                total: null,
                listLoading: true,
                listQuery: {
                    page: 1,
                    limit: 10,
                    school_id: '',
                    keyword: '',
                    start_date: '',
                    end_date: '',
                    settle_status: '',
                },
                // 导出
                exportExcels: [],
            };
        },
        created() {
            this.getList();
        },
        computed: {
            ...mapGetters(["schools", "school_id","user"])
        },
        watch: {
            school_id(newVal, oldVal) {
                this.listQuery.school_id = newVal;
                this.getList();
            },
        },
        methods: {
            getList() {
                this.listQuery.school_id = this.school_id;
                this.listLoading = true;
                request({
                    url: "/api/backend/sendman/attendanceList",
                    method: "get",
                    params: this.listQuery
                }).then(response => {
                    this.list = response.data.data;
                    this.total = response.data.total;
                    this.listLoading = false;
                });
            },
            handleFilter() {
                this.listQuery.page = 1;
                this.getList();
            },
            handleSizeChange(val) {
                this.listQuery.limit = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.listQuery.page = val;
                this.getList();
            },
            // 导出
            handleExportExcel() {
                let listQuery = JSON.parse(JSON.stringify(this.listQuery))
                listQuery.page = 1
                listQuery.limit = this.total
                request({
                    url: "/api/backend/sendman/attendanceList",
                    method: "get",
                    params: listQuery
                }).then(response => {
                    this.exportExcels = response.data.data
                    this.$nextTick(function() {
                        let wb = XLSX.utils.table_to_book(document.querySelector("#exportTable"));
                        /* get binary string as output */
                        let wbout = XLSX.write(wb, {
                            bookType: "xlsx",
                            bookSST: true,
                            type: "array"
                        });
                        try {
                            FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), "配送员考勤.xlsx");
                        } catch (e) {
                            if (typeof console !== "undefined") console.log(e, wbout);
                        }
                    })
                })
            },
        }
    };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
    .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 6px;
    }
</style>
